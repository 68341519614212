// These routes are old fashioned and will be replaced by the new route system.
// They should be nested hence a router-view in the parent component.

const SubMeterJaceIndexRoute = {
  path: '/monitors/jace',
  name: 'SubMeterJaceIndex',
  component: () =>
    import('@/Core.Service.Domain/Monitors/Jace/Components/Index'),
  meta: { permission: 'meters:read' },
};

const SubMeterJaceCreateRoute = {
  path: '/monitors/jace/create/:id',
  name: 'SubMeterJaceCreate',
  component: () =>
    import('@/Core.Service.Domain/Monitors/Jace/Components/Create'),
  meta: { permission: 'meters:create' },
};

const SubMeterJaceEditRoute = {
  path: '/monitors/jace/site/:siteId/edit/:jaceId',
  name: 'SubMeterJaceEdit',
  component: () =>
    import('@/Core.Service.Domain/Monitors/Jace/Components/Edit'),
  meta: { permission: 'meters:update' },
};

export default [
  SubMeterJaceIndexRoute,
  SubMeterJaceCreateRoute,
  SubMeterJaceEditRoute  
];
